import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Rosemary Ly | Full Stack Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Full stack developer with keen interest in DevOps and cybersecurity', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'Rose',
  subtitle: 'I work in tech!',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    "In a past life I worked as a full stack developer, now I'm in information security with a keen interest in DevOps.",
  paragraphTwo:
    "I'm GCIH, GSEC and GFACT certified and currently working towards my AWS Solution Architect Associate certification.",
  paragraphThree:
    'I enjoy learning about emerging trends in tech and cybersecurity, and you can usually find me deep in a wikipedia worm hole, lost in a riveting book at a coffee shop or exploring the world through food! ',
  paragraphFour: "I'm gainfully employed, but always happy to expand my professional network",
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'instagram-clone.png',
    title: 'Instagram Clone',
    info:
      'An instagram clone that uses React in the front end (Hooks, Context), Tailwind CSS for styling and Firebase in the backend. This project also uses Github actions for CI/CD',
    info2: 'Built with React, Tailwind CSS and Firebase',
    url: 'https://instagram-clone-cc494.web.app/',
    repo: 'https://github.com/RosemaryLy/instagram-clone', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'cryptotracker.png',
    title: 'Simple Cryptocurrency Tracker',
    info:
      "A cryptocurrency tracker that uses the coinGecko API to fetch data about cryptocurrencies and displays them in a simple list. Tailwind CSS was used for the styling of the application and local storage was used for storing a user's list",
    info2: 'Made with React, Tailwind CSS, CoinGecko API',
    url: 'https://rosemaryly.github.io/simple-crypto-tracker/',
    repo: 'https://github.com/RosemaryLy/simple-crypto-tracker', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'ChatApp.png',
    title: "Let's Chat!",
    info:
      'A single group chat application that uses React in the front end and Firestore for the back end. This chat app can autheticate a user using their Google sign in, uses React hooks with Firestore for realtime sync of data and also has security logic implemented to ban a user for life if they break community guidelines(bad words filter). This project also uses Github actions for CI/CD.',
    info2: 'Made with React, CSS and Cloud Firestore',
    url: 'https://fir-chat-app-acef6.web.app/',
    repo: 'https://github.com/RosemaryLy/firebase-chat', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'JohnPianoSchool.png',
    title: "John's Piano School",
    info: 'A custom landing page with video background for a piano teacher.',
    info2: 'Built with HTML, CSS and Javascript',
    url: 'https://rosemaryly.github.io/PianoLandingPage/',
    repo: 'https://github.com/RosemaryLy/PianoLandingPage', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'AdventCalendar.png',
    title: 'React Advent Calendar',
    info: 'A fun and simple advent calendar!',
    info2: 'Built with HTML, CSS, React and styled-components',
    url: 'https://rosemaryly.github.io/AdventCalendar/',
    repo: 'https://github.com/RosemaryLy/AdventCalendar', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'SquareBreath.png',
    title: 'Square Breathing App',
    info:
      'Square breathing is a breathing technique used to relieve stress. This app is a visual tool to help guide a user through the technique',
    info2: 'Made with HTML, CSS and JS',
    url: 'https://rosemaryly.github.io/sqaurebreath/',
    repo: 'https://github.com/RosemaryLy/sqaurebreath', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'template-portfolio.png',
    title: 'Simple Portfolio',
    info: 'A simple portflio template that is fully responsive with microinteractions ',
    info2: 'Made with HTML, CSS, Bulma, and Javascript',
    url: 'https://rosemaryly.github.io/template-HTML-portfolio/',
    repo: 'https://github.com/RosemaryLy/template-HTML-portfolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'rosemary.ly@outlook.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/RosemaryLy',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/rosemary-ly-a74552b4/',
    },
    {
      id: nanoid(),
      name: 'codepen',
      url: 'https://codepen.io/rosemaryly',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
